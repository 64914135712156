<template>
    <div v-if="detailApiLog">
        <b-row>
            <b-col offset-md="2" md="8" offset-lg="2" lg="8" sm="12">
                <div class="card">
                    <div class="card-header card-header-flex pb-2">
                        <div class="w-100 mt-2">
                            <div class="row">
                                <div class="col-10">
                                    <h5 class="mt-3 ml-0 mr-3 mb-2">
                                        <strong>
                                            <template>{{$t('title.detailApiLog')}}</template>
                                        </strong>
                                    </h5>
                                </div>
                                <b-col lg="2" md="2" sm="12" class="text-right">
                                    <b-button variant="warning" class="ml-3"
                                              size="sm" @click="handleCancelClick"
                                              v-b-tooltip.hover :title="$t('button.title.back')">
                                        <i class="fa fa-arrow-left mr-1"></i> {{$t('button.back')}}
                                    </b-button>
                                </b-col>
                            </div><!-- /.row -->
                        </div><!-- /.w-100 -->
                    </div><!-- /.card-header -->
                    <div class="card-body">
                        <b-row>
                            <b-col sm="12">
                                <table class="table table-striped">
                                    <tbody>
                                        <tr>
                                            <th>{{$t('column.orderCode')}}</th>
                                            <td> {{detailApiLog.order_code}}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('column.vinNumber')}}</th>
                                            <td>{{detailApiLog.vin_number}}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('column.ipAddress')}}</th>
                                            <td>{{detailApiLog.ip_address}}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('column.url')}}</th>
                                            <td>{{detailApiLog.url}}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('column.duration')}}</th>
                                            <td>{{detailApiLog.duration}}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('column.createdAt')}}</th>
                                            <td>{{$global.utcDateToLocalDate(detailApiLog.created_at) }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </b-col><!-- /.col -->
                            <b-col sm="12" class="mt-3">
                                <h4><span class="text-capitalize">{{$t('column.header')}}</span></h4>
                                <hr>
                                <b-row>
                                    <b-col sm="10" class="mb-5">
                                        <div>
                                            <vue-json-pretty
                                                :path="'res'"
                                                :data="JSON.parse(detailApiLog.request_header)">
                                            </vue-json-pretty>
                                        </div>
                                    </b-col><!-- /.col -->
                                </b-row><!-- /.row -->
                            </b-col>
                            <b-col sm="12" class="mt-3">
                                <h4><span class="text-capitalize">{{$t('column.originalProperties')}}</span></h4>
                                <hr>
                                <b-row>
                                    <b-col sm="10" class="mb-5">
                                        <div>
                                            <vue-json-pretty
                                                :path="'res'"
                                                :data="JSON.parse(detailApiLog.original_properties)">
                                            </vue-json-pretty>
                                        </div>
                                    </b-col><!-- /.col -->
                                </b-row><!-- /.row -->
                            </b-col>
                            <b-col sm="12" class="mt-3">
                                <h4><span class="text-capitalize">{{$t('column.response')}}</span></h4>
                                <hr>
                                <b-row>
                                    <b-col sm="10" class="mb-5">
                                        <div>
                                            <vue-json-pretty
                                                :path="'res'"
                                                :data="JSON.parse(detailApiLog.response)">
                                            </vue-json-pretty>
                                        </div>
                                    </b-col><!-- /.col -->
                                </b-row><!-- /.row -->
                            </b-col>
                        </b-row><!-- /.row -->
                    </div><!-- /.card-body-->
                </div><!-- /.card -->
            </b-col>
        </b-row>
    </div><!--/div-->
</template>
<script>
    import {request} from "@/util/Request";
    import VueJsonPretty from 'vue-json-pretty'
    import 'vue-json-pretty/lib/styles.css'

    export default {
        props: ['id', 'handleCloseOperation'],
        components: {
            VueJsonPretty
        },
        data() {
            return {
                detailApiLog: null,
                items: []
            }
        },
        mounted() {
            if (this.$route.query && this.$route.query.operation === 'detail' && this.$route.query.oToken) {
                this.getDetail(this.$route.query.oToken)
            } else {
                this.serverError()
            }
        },
        methods: {
            async getDetail(id) {
                await request({
                    url: `order/api/logs/client/detail/${id}`,
                })
                    .then((response) => {
                        const {data} = response
                        this.detailApiLog = data
                    })
                    .catch((error) => {

                    })
            },
            handleCancelClick() {
                this.handleCloseOperation()
            }
        }
    }
</script>
