<template>
    <div v-if="show">
        <div class="card" v-show="operation !== 'detail'">
            <div class="card-header card-header-flex pb-2">
                <div class="w-100 mt-2">
                    <div class="row">
                        <div class="col-8">
                            <h5 class="mt-3 ml-0 mr-3 mb-2">
                                <strong>
                                    <template v-if="operation === null">{{$t('title.apiLogs')}}</template>
                                    <template v-else>{{ $t(operationTitle) }}</template>
                                </strong>
                            </h5>
                        </div>
                        <div class="col-4 text-right">
                            <div v-if="operation === null">
                                <div class="mt-3">
                                    <!-- Using components -->
                                    <b-input-group class="mt-3">
                                        <b-form-input type="search" class="form-control form-control-sm"
                                                      :placeholder="$t('input.whatAreYouLookingFor')"
                                                      v-on:keyup.enter="handleSearch" v-model="search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button size="sm" :title="$t('button.title.filterRecords')"
                                                      variant="outline-info"
                                                      @click="filters.visible = !filters.visible" v-b-tooltip.hover
                                                      v-if="$global.hasPermission('ordersownview')">
                                                <i class="fa fa-filter"></i>
                                            </b-button>
                                            <b-button size="sm" :title="$t('button.title.resetList')"
                                                      variant="outline-info"
                                                      @click="handleResetFilterClick()" v-b-tooltip.hover>
                                                <i class="fa fa-refresh"></i>
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </div>
                            </div>
                            <div v-else>
                                <b-button variant="warning" size="sm" class="mt-3"
                                          @click="handleOperationClose()"
                                          v-b-tooltip.hover :title="$t('button.title.cancel')">
                                    <i class="fa fa-arrow-left"></i> {{$t('button.cancel')}}
                                </b-button>
                            </div>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.w-100 -->
            </div><!-- /.card-header -->
            <div class="card-body">
                <div class="event-log-table">
                    <b-table hover responsive
                             ref="table"
                             :busy="listingLoading"
                             :items="dataSource"
                             :fields="columns"
                             :sort-by.sync="sortField"
                             @sort-changed="handleSortChange">
                        <template v-slot:cell(type)="record">
                            <span v-if="record.item.type === 'create'"
                                  class="badge badge-pill badge-success">Create</span>
                            <span v-if="record.item.type === 'update'"
                                  class="badge badge-pill badge-warning">Update</span>
                        </template>
                        <template v-slot:cell(created_at)="record">
                            {{$global.utcDateToLocalDate(record.item.created_at) }}
                        </template>
                        <template v-slot:cell(action)="record">
                            <a @click="setOperation('detail', record.item.id)"
                               :title="$t('button.title.detailItem')" class="ml-1"
                               v-b-tooltip.hover>
                                <i class="icmn-info"></i>
                            </a>
                        </template>
                    </b-table><!-- /.b-table -->
                    <div class="clearfix">
                        <div class="float-left">
                            <b-form-select v-model="pagination.perPage" :options="[5, 10, 50, 100, 1000]" size="sm"></b-form-select>
                        </div>
                        <div class="float-right">
                            <b-pagination
                                v-model="pagination.current"
                                :total-rows="pagination.total"
                                :per-page="pagination.perPage"
                                :first-text="$t('paginations.first')"
                                :prev-text="$t('paginations.prev')"
                                :next-text="$t('paginations.next')"
                                :last-text="$t('paginations.last')"
                            ></b-pagination>
                        </div><!-- /.pull-right -->
                    </div><!-- /.clearfix -->
                </div><!-- /.event-log-table -->
                <div class="event-log-operation"></div><!--/.event-log-operation-->
                <div class="filter-container">
                    <a-drawer
                        placement="left"
                        :width="'360px'"
                        :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                        :closable="false"
                        @close="filters.visible = !filters.visible"
                        :visible="!operation && filters.visible"
                        :zIndex="10"
                        :title="$t('title.advanceFilters')"
                    >
                        <form @submit.prevent="loadList(listQueryParams, true)" autocomplete="off">
                            <b-row>
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.fromAddedDate')"
                                        label-for="fromDate">
                                        <b-form-datepicker placeholder="" id="fromDate" v-model="filters.from_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.toAddedDate')"
                                        label-for="toDate">
                                        <b-form-datepicker placeholder="" id="toDate" v-model="filters.to_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                            </b-row>
                            <div class="drawer-footer">
                                <b-button size='sm' variant="info" @click="filters.visible = !filters.visible"
                                          class="mr-2" :title="$t('button.title.closePanel')" v-b-tooltip.hover>
                                    {{$t('button.close')}}
                                </b-button>
                                <b-button size='sm' variant="warning" @click="handleResetFilterClick" class="mr-2"
                                          :title="$t('button.title.resetFilter')" v-b-tooltip.hover>
                                    {{$t('button.reset')}}
                                </b-button>
                                <b-button size='sm' variant="primary" button="submit" type="filled"
                                          :title="$t('button.title.filterRecords')" v-b-tooltip.hover>
                                    {{$t('button.apply')}}
                                </b-button>
                            </div><!-- /.drawer-footer -->
                        </form>
                    </a-drawer>
                </div><!-- /.filter-container -->
            </div><!-- /.card-body-->
        </div><!-- /.card -->
        <div v-if="operation === 'detail'">
            <detail :handle-close-operation="handleOperationClose"></detail>
        </div>
    </div>
</template>
<script>
import ListingMixin from '../../../util/ListingMixin'
import {mapState} from 'vuex'
import Datepicker from 'vuejs-datepicker'
import Treeselect from '@riophae/vue-treeselect'
import Detail from './detail'

const FORM_STATE = {
    _method: 'post',
};

const FILTER_STATE = {
    visible: false,
    countries: [],
    from_date: null,
    to_date: null,
};

const COLUMN_DEFINITION = (self) => [
    {
        label: self.$t('column.orderCode'),
        key: 'order_code',
        sortable: true,
        sortKey: 'order_code',
    },
    {
        label: self.$t('column.vinNumber'),
        key: 'vin_number',
        sortable: true,
        sortKey: 'vin_number',
    },
    {
        label: self.$t('column.responseCode'),
        key: 'code',
        sortable: true,
        sortKey: 'code',
    },
    {
        label: 'IP',
        key: 'ip_address',
        sortable: true,
        sortKey: 'ip_address',
    },
    {
        label:  self.$t('column.duration'),
        key: 'duration',
        sortable: true,
        sortKey: 'duration',
    },
    {
        label: self.$t('column.createdAt'),
        key: 'created_at',
        sortable: true,
        sortKey: 'created_at',
        class: 'w-100px',
    },
    {
        label: self.$t('column.action'),
        class: 'text-right w-100px',
        key: 'action',
    }
];

export default {
    mixins: [ListingMixin],
    components: {
        Datepicker,
        Detail,
        Treeselect
    },
    data() {
        return {
            operationTitle: 'title.apiLogs',
            formFields: {...FORM_STATE},
            filters: {...FILTER_STATE},
            listUrl: 'order/api/logs/client',
            columns: COLUMN_DEFINITION(this),
            show: true,
        }
    },
    mounted() {
        this.$title = this.$t('topBar.navigations.modules.apiLogs')
    },
    methods: {
        setOperation(operation = 'add', operationToken = null) {
            this.operationTitle = `title.${operation}apiLog`;
            this.$router.replace({
                query: Object.assign({},
                    this.$route.query,
                    {
                        ...this.listQueryParams,
                        operation: operation,
                        oToken: operationToken,
                    },
                ),
            }).then(() => {
            }).catch(() => {
            })
        },
        async handleSubmit() {

        },
        hasListAccess() {
            return this.$global.hasRole('client')
        },
        getExtraParams() {
            return {
                filters: {
                    ...this.filters,
                    from_date: ((this.filters.from_date) ? this.$global.dateToUtcDate(this.filters.from_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                    to_date: ((this.filters.to_date) ? this.$global.dateToUtcDate(this.filters.to_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                },
            }
        },
        handleResetFilterClick() {
            this.filters = {...FILTER_STATE}
            this.isFilterApplied = 'reset'
            this.handleResetClick()
            this.loadList(this.listQueryParams)
        },
        afterCloseOperation() {
            this.formFields = {...FORM_STATE}
        }
    },
    computed: {
        ...mapState([
            'global',
            'settings'
        ]),
    },
}
</script>
